import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            position: 'relative'
        },
        fallbackContainer: {
            backgroundColor: `${theme.palette.secondary.main}33`,
            borderTopLeftRadius: theme.spacing(0.5),
            borderTopRightRadius: theme.spacing(0.5)
        },
        duration: {
            backgroundColor: `${theme.palette.common.black}B2`,
            color: theme.palette.common.white,
            position: 'absolute',
            bottom: 6,
            right: theme.spacing(1),
            padding: '1px 3px',
            lineHeight: '14px',
            fontWeight: 'bold',
            fontSize: 12,
            ...theme.border({ radius: 3, color: theme.palette.body.light })
        }
    }),
    {
        name: 'LectureThumbnail'
    }
);

export default useStyles;
