import { getCountryCode, useMembershipContext } from 'common';
import React from 'react';
import { useIntl } from 'react-intl';

import { useMediathekCoursesQuery, useTopicSortOrderQuery } from '../../../graphql/catalog/queries';
import {
    getCustomTopicSortOrder,
    getFetchCoursesVariables,
    prepareCoursesData
} from './CoursesSection.utils';
import { MediathekSectionHeader } from '../MediathekSectionHeader';
import { SkeletonCoursesTable } from './components/SkeletonCoursesTable';
import { CoursesTable, CoursesTableProps } from './components/CoursesTable';
import { ShowAllCoursesButton } from './ShowAllCoursesButton';

type CoursesSectionProps = {
    selectedTopicCode?: string;
    title: string;
    filterSelectedTopicCode?: boolean;
};

export const CoursesSection = React.memo(
    ({ selectedTopicCode, title, filterSelectedTopicCode }: CoursesSectionProps) => {
        const intl = useIntl();
        const { locale } = intl;

        const country = getCountryCode(locale);

        const { data: topicsResponse } = useTopicSortOrderQuery({
            variables: getCustomTopicSortOrder(selectedTopicCode)
        });

        const { data: coursesData } = useMediathekCoursesQuery({
            variables: getFetchCoursesVariables(country)
        });
        const { isMember } = useMembershipContext();

        const courses: CoursesTableProps['courses'] = prepareCoursesData({
            topicData: topicsResponse?.topicDocuments.data,
            coursesData: coursesData?.contentDocuments,
            intl,
            toFilterTopicCode: filterSelectedTopicCode ? selectedTopicCode : undefined,
            isMember
        });

        if (!courses.length) {
            return null;
        }

        return (
            <>
                <MediathekSectionHeader text={title} data-testid="courses-table-title" />
                {!courses.length ? (
                    <SkeletonCoursesTable />
                ) : (
                    <CoursesTable courses={courses} variant="MULTIPRODUCT" />
                )}
                <ShowAllCoursesButton totalCount={coursesData?.contentDocuments?.totalCount} />
            </>
        );
    }
);
