import { Types } from 'common';
import { LECTURES_PER_PAGE } from '../../MediathekHome.utils';
import { formatDateForDB } from 'components';
import Dayjs from 'dayjs';
import getContentsDefinitionStartDateFilter from '../../../../utils/queries/getContentsDefinitionStartDateFilter';
import { ContentContentTypeFilter } from '../../../../components/Filters';

type GetHomePageTopicVideosQueryUK = {
    countryCode: string;
    pageNumber: number;
    topics: Array<string>;
    isMainTopic: boolean;
    childCodes: Array<string>;
};

export const getHomePageTopicVideosQueryUK = ({
    pageNumber,
    countryCode,
    topics,
    isMainTopic,
    childCodes
}: GetHomePageTopicVideosQueryUK) => ({
    variables: {
        searchQuery: {
            pageNumber,
            pageSize: LECTURES_PER_PAGE,
            filters: [
                {
                    field: 'isAvailableOnHomepage',
                    operation: Types.SearchFilterOperation.Equal,
                    value: ['true']
                },
                {
                    field: 'validDate',
                    operation: Types.SearchFilterOperation.GreaterThan,
                    value: [formatDateForDB(Dayjs.utc().startOf('hour'))]
                },
                {
                    field: 'publishedDomains',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [countryCode]
                },
                {
                    field: 'language',
                    operation: Types.SearchFilterOperation.Contain,
                    value: ['en-GB']
                },
                {
                    field: 'topics.code',
                    operation: Types.SearchFilterOperation.Equal,
                    value: topics
                },
                {
                    field: 'brandType',
                    operation: Types.SearchFilterOperation.NotEqual,
                    value: [Types.ProductBrand.Summedup]
                },
                {
                    subFilterCondition: Types.SearchFilterCondition.Or,
                    subFilters: [
                        {
                            field: 'contentType',
                            operation: Types.SearchFilterOperation.Equal,
                            value: [Types.ContentType.OnDemandAccredited]
                        },
                        {
                            field: 'parent.contentType',
                            operation: Types.SearchFilterOperation.Contain,
                            value: [Types.ContentType.OnDemandAccredited]
                        },
                        {
                            field: 'brandType',
                            operation: Types.SearchFilterOperation.Contain,
                            value: [
                                Types.ProductBrand.Wuex,
                                Types.ProductBrand.Wuif,
                                Types.ProductBrand.Wu660
                            ],
                            subFilterCondition: Types.SearchFilterCondition.Or,
                            subFilters: [
                                {
                                    field: 'contentType',
                                    operation: Types.SearchFilterOperation.Equal,
                                    value: [Types.ContentType.Webinar]
                                },
                                {
                                    field: 'parent.contentType',
                                    operation: Types.SearchFilterOperation.Contain,
                                    value: [Types.ContentType.Webinar]
                                }
                            ]
                        }
                    ]
                },
                {
                    field: 'willBeOnDemand',
                    operation: Types.SearchFilterOperation.Equal,
                    value: ['true']
                },
                ...(isMainTopic && childCodes.length > 0
                    ? [
                          {
                              field: 'topics.code',
                              operation: Types.SearchFilterOperation.NotEqual,
                              value: childCodes
                          }
                      ]
                    : []),
                ...(getContentsDefinitionStartDateFilter(ContentContentTypeFilter.LECTURE) ?? [])
            ],
            orders: ['-startDateTime']
        }
    }
});
