import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useCustomFormik } from 'components/src/hooks';
import {
    ContactIcons,
    getHeaderLinks,
    HeaderLinks,
    LocaleSwitch,
    LOCALE_TO_COUNTRY_CODE,
    useIsDesktop,
    useMarketingHomeContext,
    UserValidation,
    USER_URLS,
    useUserContext,
    HeaderLink
} from 'common';
import { Button, FormikTextField, Logo, Typography } from 'components';

const useStyles = makeStyles((theme) => ({
    container: {
        background: '#12385C',
        position: 'relative',
        padding: theme.spacing(9),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
            marginTop: theme.headerHeight.sm
        }
    },
    headerContainer: {
        display: 'flex',
        marginBottom: theme.spacing(9),
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3)
        }
    },
    logoTitleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        height: 100,
        '& path:not:first-child': {
            fill: 'white'
        },
        width: 'fit-content',
        [theme.breakpoints.down('md')]: {
            height: 36,

            '& path:nth-child(2)': {
                fill: 'white'
            }
        }
    },
    logoTitle: {
        color: '#86E1FF',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '1px',
        textTransform: 'uppercase',
        fontFamily: 'Poppins',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    localeSwitch: {
        '& svg': {
            fill: 'white',
            stroke: 'white',
            strokeWidth: 1
        },
        '& span': {
            color: 'white'
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        color: 'white',
        fontWeight: 700,
        fontFamily: 'Poppins',
        fontSize: 44,
        lineHeight: '66px',
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            fontSize: 34,
            textAlign: 'center',
            lineHeight: '51px',
            marginBottom: theme.spacing(3)
        }
    },
    subtitle: {
        color: theme.palette.secondary[100],
        fontWeight: 600,
        fontSize: 29,
        lineHeight: '43.5px',
        fontFamily: 'Poppins',
        [theme.breakpoints.down('md')]: {
            fontSize: 21,
            textAlign: 'center',
            lineHeight: '31px'
        },
        '&:first-of-type': {
            marginBottom: theme.spacing(2)
        }
    },
    formOuterContainer: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'start',
            '& > div': {
                width: '100%'
            }
        }
    },
    formSubtitle: {
        color: 'white',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '30px',
        margin: '10px 16px 34px 16px',
        fontFamily: 'Poppins',
        letterSpacing: '0.3px',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            fontSize: 14,
            lineHeight: '20px',
            marginBottom: 0
        }
    },
    submitButton: {
        height: '100% !important',
        marginLeft: theme.spacing(2),
        textTransform: 'none',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            width: '100%',
            marginTop: theme.spacing(1)
        }
    },
    buttonWithError: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            marginBottom: 0
        }
    },
    emailInput: {
        width: '436px',
        height: 56,
        '& > div': {
            background: 'white',
            borderRadius: '6px'
        },
        '& label': {
            color: '#12385C'
        },
        '& input::placeholder': {
            color: '#12385C'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    footerContainer: {
        width: 1440,
        display: 'flex',
        position: 'absolute',
        bottom: theme.spacing(-3),
        alignItems: 'end',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    halfCircle: {
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 250px)',
        height: 500,
        filter: 'brightness(0) saturate(100%) invert(93%) sepia(31%) saturate(4133%) hue-rotate(163deg) brightness(97%) contrast(96%)'
    },
    headerLinksContainer: {
        padding: theme.spacing(1),
        borderRadius: '6px',
        boxShadow: '0px 4px 6px 0px #0000000A',
        width: '100%',
        height: '55px',
        background: 'white',
        display: 'flex',
        alignItems: 'end',
        '& > div': {
            zIndex: 1
        }
    },
    contactLinksContainer: {
        display: 'flex',
        alignItems: 'end',
        marginLeft: 'auto'
    }
}));

const GuestMarketingBanner = () => {
    const isDesktop = useIsDesktop();
    const { isLoggedIn } = useUserContext();
    const classes = useStyles();
    const { isMarketingHomeEnabled } = useMarketingHomeContext();
    const { formatMessage, locale } = useIntl();
    const { pathname, push } = useRouter();
    const [loading, setLoading] = useState(false);

    const formik = useCustomFormik({
        initialValues: {
            email: ''
        },
        validationSchema: yup.object({
            email: UserValidation.email()
        }),
        onSubmit: async (values) => {
            setLoading(true);
            push(
                USER_URLS.registration({
                    email: values.email
                })
            );
        }
    });
    const hasError = Object.keys(formik.errors).length > 0;
    const headerLinks = getHeaderLinks({
        countryCode: LOCALE_TO_COUNTRY_CODE[locale],
        locale,
        flags: {},
        query: {},
        pathname,
        isLoggedIn: false
    });

    if (isLoggedIn || !isMarketingHomeEnabled) {
        return null;
    }

    return (
        <>
            <Box id="guest-marketing-banner">
                <Box className={classes.container}>
                    <img
                        src="https://storage.googleapis.com/bk-public-prod/public/static/decorative/FOMF-Shape-Half.svg"
                        alt=""
                        className={classes.halfCircle}
                    />
                    <Box className={classes.headerContainer}>
                        <Box className={classes.logoTitleContainer}>
                            <Logo
                                variant={isDesktop ? 'text' : 'no_text'}
                                className={classes.logo}
                                locale={locale}
                            />
                            <Typography
                                className={classes.logoTitle}
                                localeId="common.guest-banner.logo-title"
                            />
                        </Box>
                        <LocaleSwitch className={classes.localeSwitch} />
                    </Box>
                    <Box className={classes.contentContainer}>
                        <Typography
                            className={classes.title}
                            localeId="common.guest-banner.title"
                            variant="h1"
                        />
                        <Typography
                            className={classes.subtitle}
                            localeId="common.guest-banner.subtitle2"
                            variant="h4"
                        />
                        <Typography
                            className={classes.subtitle}
                            localeId="common.guest-banner.subtitle1"
                            variant="h4"
                        />
                        <Box className={classes.formOuterContainer}>
                            <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
                                <FormikTextField
                                    name="email"
                                    nativeAutocomplete="email"
                                    gridProps={{ xs: 12 }}
                                    label={formatMessage({
                                        id: 'common.guest-banner.form-label'
                                    })}
                                    required={false}
                                    formik={formik}
                                    className={classes.emailInput}
                                    hideClearIcon
                                />
                                <Button
                                    disabled={loading}
                                    type="submit"
                                    localeId="common.guest-banner.submit"
                                    color="secondary"
                                    uppercase={false}
                                    className={classNames(classes.submitButton, {
                                        [classes.buttonWithError]: hasError
                                    })}
                                />
                            </form>
                        </Box>
                        <Container className={classNames(classes.footerContainer)} disableGutters>
                            <Box className={classes.headerLinksContainer}>
                                <HeaderLinks menuItems={headerLinks} />
                                <Box className={classes.contactLinksContainer}>
                                    <HeaderLink
                                        localeId="common.login.title"
                                        href={USER_URLS.login}
                                    />
                                    <ContactIcons />
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default GuestMarketingBanner;
