import { useCallback } from 'react';
import { useContentDocumentsSimplifiedLazyQuery } from '../graphql/catalog/queries';
import { Types } from 'common';

const useIsContentAvailable = () => {
    const [fetchData] = useContentDocumentsSimplifiedLazyQuery();
    const isContentAvailable = useCallback(
        async (query: { variables: { searchQuery: Types.SearchQuery } }) => {
            const { data } = await fetchData(query);
            return (data?.contentDocuments?.totalCount ?? 0) > 0;
        },
        []
    );

    return isContentAvailable;
};

export default useIsContentAvailable;
