import React from 'react';
import { GetServerSideProps } from 'next';
import {
    getTopicsSearchQuery,
    TOPICS_PER_PAGE
} from '../containers/MediathekHome/MediathekHome.utils';
import {
    CurrentUserSpeciality,
    CurrentUserSpecialityQuery,
    MediathekCourses,
    RecentlyStartedCourses,
    RecentlyStartedCoursesQuery,
    SearchForTopics,
    SearchForTopicsQuery,
    TopicsForSelector,
    TopicsForSelectorQuery,
    TopicSortOrder,
    TopicSortOrderQuery,
    MediathekCoursesQuery
} from '../graphql/catalog/queries';
import { ACTIVE_TOPIC_CODE } from '../utils/topics';
import {
    getCustomTopicSortOrder,
    getFetchCoursesVariables
} from '../containers/MediathekHome/CoursesSection/CoursesSection.utils';
import { getInitialTopic } from '../utils/ssr/topic';
import { MediathekHomePage } from '../containers/MediathekHome/MediathekHome';
import type { NextPageWithLayout } from './_app';
import {
    DEFAULT_LOCALE,
    addApolloState,
    getCountryCode,
    SsrAuthQueries,
    initializeApollo,
    getLaunchDarklyServerClient,
    setUpLdContexts,
    getAnonymousId,
    isProfileComplete,
    Queries,
    getAddonsQueryVariables,
    Types,
    getCountryCode3
} from 'common';

interface PageProps {
    initialTopicCode: string;
}
const HomePage: NextPageWithLayout<PageProps> = ({ initialTopicCode }) => {
    return <MediathekHomePage initialTopicCode={initialTopicCode} />;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    const cookie = context.req.headers.cookie;

    const anonymousId = getAnonymousId(cookie);
    const apolloClient = initializeApollo(null, {
        batchHttpLinkOptions: { headers: { cookie } }
    });

    const locale = (context.locale || DEFAULT_LOCALE) as Locale;
    const initalTopic = await getInitialTopic(context);
    let { selectedTopicCode } = initalTopic;
    const { fromQuery } = initalTopic;
    const promises: Array<Promise<unknown>> = [];

    let marketingHomepageFlag = false;
    try {
        const { authenticatedUser, user, userMembership } =
            await SsrAuthQueries.ssrFetchUserAndMembership(apolloClient, locale);
        if (authenticatedUser && user) {
            const profileCompleted = isProfileComplete(user, locale);
            if (!profileCompleted) {
                const newUrl = '/user/complete-profile';
                const urlWithLocale =
                    locale !== context.defaultLocale ? `/${locale}${newUrl}` : newUrl;
                return {
                    redirect: {
                        destination: urlWithLocale,
                        permanent: false
                    }
                };
            }
            const resSpecialities = await apolloClient.query<CurrentUserSpecialityQuery | null>({
                query: CurrentUserSpeciality
            });
            // query topic takes precedence over user speciality
            if (!fromQuery) {
                selectedTopicCode =
                    resSpecialities.data?.topicDocuments.data[0]?.code ?? ACTIVE_TOPIC_CODE;
            }
            if (userMembership?.addedToGroupMembershipDateTime) {
                await apolloClient.query<Queries.GetAddOnCoursesQuery | null>({
                    query: Queries.GetAddOnCourses,
                    variables: getAddonsQueryVariables()
                });
            }
            await Promise.allSettled([
                apolloClient.query<RecentlyStartedCoursesQuery | null>({
                    query: RecentlyStartedCourses,
                    variables: {
                        contentType: [Types.ContentType.OndemandCourse],
                        brandType: [Types.ProductBrand.Syfat, Types.ProductBrand.Syrot],
                        country: [getCountryCode3(locale)]
                    }
                }),
                apolloClient.query<RecentlyStartedCoursesQuery | null>({
                    query: RecentlyStartedCourses,
                    variables: {
                        contentType: [Types.ContentType.OndemandCourse],
                        brandType: [Types.ProductBrand.OndemandAccredited],
                        country: [getCountryCode3(locale)]
                    }
                })
            ]);
        } else {
            const ldClient = await getLaunchDarklyServerClient();
            if (locale !== 'en-GB') {
                marketingHomepageFlag = await ldClient?.variation(
                    'learning-marketing-homepage-temp-05122024',
                    await setUpLdContexts(user, anonymousId, getCountryCode(locale)),
                    false
                );
            }
        }
        // log the error and allow proceeding, queries will be done on client side.
    } catch (err) {
        console.error(err);
    }

    // topics
    promises.push(
        apolloClient.query<TopicsForSelectorQuery | null>({
            query: TopicsForSelector
        })
    );

    promises.push(
        apolloClient.query<SearchForTopicsQuery | null>({
            query: SearchForTopics,
            variables: getTopicsSearchQuery({
                pageNumber: 0,
                pageSize: TOPICS_PER_PAGE,
                levels: ['0', '1'],
                parentCode: null
            })
        })
    );

    // courses
    promises.push(
        apolloClient.query<TopicSortOrderQuery | null>({
            query: TopicSortOrder,
            variables: getCustomTopicSortOrder(selectedTopicCode)
        })
    );
    promises.push(
        apolloClient.query<MediathekCoursesQuery | null>({
            query: MediathekCourses,
            variables: getFetchCoursesVariables(getCountryCode(locale))
        })
    );

    try {
        await Promise.all(promises);
        return addApolloState(apolloClient, {
            props: {
                initialTopicCode: selectedTopicCode,
                anonymousId,
                isMarketingHomeEnabled: marketingHomepageFlag
            }
        });
    } catch (err) {
        console.error(err);
        return {
            props: {
                initialTopicCode: selectedTopicCode,
                anonymousId,
                isMarketingHomeEnabled: marketingHomepageFlag
            }
        };
    }
};

export default HomePage;
