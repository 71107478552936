import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton, ThemeTypes } from 'components';
import { dimensions } from './WebUpItem.styles';

const useStyles = makeStyles<ThemeTypes.Theme>((theme) => ({
    container: {
        margin: dimensions.small.container.margin,
        height: dimensions.small.container.height,
        width: dimensions.small.container.width,
        ...theme.border({ color: theme.palette.body.light, radius: 6 }),
        [theme.breakpoints.up('xl')]: {
            margin: dimensions.large.container.margin,
            height: dimensions.large.container.height,
            width: dimensions.large.container.width
        }
    },
    image: {
        borderTopLeftRadius: 6,
        width: '100%',
        borderTopRightRadius: 6
    },
    content: {
        padding: theme.spacing(2, 3),
        position: 'relative',
        height: `calc(${dimensions.small.container.height}px - ${dimensions.small.image.height}px)`,
        [theme.breakpoints.up('xl')]: {
            height: `calc(${dimensions.large.container.height}px - ${dimensions.large.image.height}px)`
        }
    },
    footer: {
        position: 'absolute',
        bottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        right: theme.spacing(3),
        left: theme.spacing(3)
    }
}));

const SkeletonWebUpItem = () => {
    const classes = useStyles();
    const theme = useTheme();
    const size = useMediaQuery(theme.breakpoints.up('xl')) ? 'large' : 'small';
    return (
        <div className={classes.container}>
            <Skeleton
                className={classes.image}
                variant="rectangular"
                height={dimensions[size].image.height}
            />
            <Box className={classes.content}>
                <Skeleton variant="rectangular" width={60} height={8} />
                <Box paddingTop={2}>
                    <Skeleton variant="rectangular" width={241} height={20} />
                </Box>
                <Box paddingTop={2}>
                    <Skeleton variant="rectangular" width={251} height={20} />
                </Box>
                <Box paddingTop={2}>
                    <Skeleton variant="rectangular" width={196} height={20} />
                </Box>
                <Box className={classes.footer}>
                    <Skeleton variant="rectangular" width={86} height={8} />
                    <Skeleton variant="rectangular" width={57} height={8} />
                </Box>
            </Box>
        </div>
    );
};

export default SkeletonWebUpItem;
