import React from 'react';
import { Box } from '@mui/material';
import { Skeleton } from 'components';

import classnames from 'classnames';
import useStyles from './AddonCard.styles';
import { Props } from './AddonCard';

type SkeletonProps = Pick<Props, 'className' | 'isCompact'>;

const AddonCardSkeleton = (props: SkeletonProps) => {
    const { isCompact, className } = props;
    const classes = useStyles(props);

    return (
        <Box className={classnames(classes.container, isCompact && classes.compact, className)}>
            <Box className={classes.titleContainer}>
                <Skeleton width="75%" height={36} />
                <Skeleton width="60%" height={28} style={{ marginTop: 8 }} />
            </Box>
            <Skeleton width="50%" height={28} className={classes.progressContainer} />
            <Box className={classes.actionButtonContainer}>
                <Skeleton width="100%" height={42} />
            </Box>
        </Box>
    );
};

export default AddonCardSkeleton;
