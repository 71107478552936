import { CATALOG_URLS, buildURLWithParams, Types, generateContentURL } from 'common';
import { useState, useEffect } from 'react';
import { useGetCompanyPageSlugLazyQuery } from '../graphql/catalog/queries';

interface BuildPartnerContentURLProps {
    slug?: string | null;
    providerId?: string | null;
    contentId: string;
}

export const buildPartnerContentURL = ({
    slug,
    providerId,
    contentId
}: BuildPartnerContentURLProps) => {
    const newUrl = buildURLWithParams(CATALOG_URLS.partnerVideo, [
        slug || providerId || '',
        'all',
        contentId
    ]);

    return newUrl;
};

interface UsePartnerSearchItemUrlProps {
    content: {
        contentId: string;
        brand?: Types.ProductBrand | null;
    };
    providerId?: Types.UUID | null;
    isInTheFuture?: boolean;
}

export const usePartnerSearchItemUrl = ({
    content,
    providerId,
    isInTheFuture
}: UsePartnerSearchItemUrlProps) => {
    const [url, setUrl] = useState<string | null>(null);
    const [fetchCompanyPageSlug] = useGetCompanyPageSlugLazyQuery();

    useEffect(() => {
        const fetchUrl = async () => {
            if (isInTheFuture && content.brand !== Types.ProductBrand.Summedup) {
                setUrl(generateContentURL({ contentId: content.contentId }));
                return;
            }

            let slug: string | null = null;
            if (providerId) {
                const response = await fetchCompanyPageSlug({ variables: { id: providerId } });
                if (response.data?.company?.companyPage?.slug) {
                    slug = response.data.company.companyPage.slug;
                }
            }

            const partnerContentUrl = buildPartnerContentURL({
                slug,
                providerId,
                contentId: content.contentId
            });
            setUrl(partnerContentUrl);
        };

        fetchUrl();
    }, [content.contentId, content.brand, providerId, isInTheFuture, fetchCompanyPageSlug]);

    return url;
};
