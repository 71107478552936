import makeStyles from '@mui/styles/makeStyles';
import { formatters, getCountryCode, useMembershipContext } from 'common';
import { Button, Icon } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    useTopicsForSelectorQuery,
    useTopicSortOrderQuery,
    MediathekCoursesQuery,
    useMediathekCoursesQuery
} from '../../../../graphql/catalog/queries';
import { MediathekSectionHeader } from '../../MediathekSectionHeader';
import {
    getCustomTopicSortOrder,
    getFetchCoursesVariables,
    prepareTopicCoursesData
} from '../CoursesSection.utils';
import { CoursesTable, CoursesTableProps } from './CoursesTable';
import { SkeletonCoursesTable } from './SkeletonCoursesTable';

const LIMIT_COURSES_TABLE = 5;

const useStyles = makeStyles(
    (theme) => ({
        seeMoreButton: {
            '& > span': {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
                fontSize: 17,
                marginInline: theme.spacing(3)
            },
            width: 'fit-content',
            marginBlockStart: theme.spacing(5),
            marginInline: theme.spacing(4),
            height: '50px',
            textTransform: 'none',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                marginBlockStart: theme.spacing(1),
                marginInline: theme.spacing(0)
            }
        }
    }),
    {
        name: 'CoursesTopicTable',
        index: 10
    }
);

type Props = {
    selectedTopicCode: string | undefined;
    sortedByCoursesTopicCodes: Array<[string, MediathekCoursesQuery['contentDocuments']['data']]>;
};

export function CoursesTopicTable({ selectedTopicCode, sortedByCoursesTopicCodes }: Props) {
    const classes = useStyles();
    const { isMember } = useMembershipContext();
    const [displayAllCourses, setDisplayAllCourses] = useState(false);
    const intl = useIntl();
    const countryCode = getCountryCode(intl.locale);

    const { data: topicsData } = useTopicsForSelectorQuery();
    const { data: topicsResponse, loading: isLoadingTopics } = useTopicSortOrderQuery({
        variables: getCustomTopicSortOrder(selectedTopicCode)
    });
    const { data: coursesData, loading: isLoadingCourses } = useMediathekCoursesQuery({
        variables: getFetchCoursesVariables(countryCode)
    });

    const selectedTopicTitle = useMemo(() => {
        const found = topicsData?.topicDocuments.data.find((t) => t.code === selectedTopicCode);
        if (found) {
            return formatters.formatTranslation(found.description, { locale: intl.locale });
        }
    }, [selectedTopicCode, topicsData]);

    const filteredCourses: MediathekCoursesQuery['contentDocuments'] = {
        ...coursesData?.contentDocuments,
        data:
            coursesData?.contentDocuments.data.filter((con) =>
                con.product?.topics.find((t) => t.code === selectedTopicCode)
            ) ?? []
    };

    const courses: CoursesTableProps['courses'] = useMemo(() => {
        let courses = prepareTopicCoursesData({
            topicData: topicsResponse?.topicDocuments.data,
            coursesData: filteredCourses,
            intl,
            isMember
        });
        if (!displayAllCourses) {
            courses = courses.slice(0, LIMIT_COURSES_TABLE);
        }
        return courses;
    }, [topicsResponse?.topicDocuments.data, filteredCourses, displayAllCourses]);

    const countOfCoursesThatDidntFitInTheTable = useMemo(() => {
        const selectedCourses = sortedByCoursesTopicCodes.find(
            (en) => en[0] === selectedTopicCode
        )?.[1];
        const count =
            selectedCourses?.length !== undefined
                ? selectedCourses.length - LIMIT_COURSES_TABLE
                : undefined;
        return count ?? 0;
    }, [sortedByCoursesTopicCodes, selectedTopicCode]);

    useEffect(() => {
        setDisplayAllCourses(false);
    }, [selectedTopicCode]);

    const loading = isLoadingCourses || isLoadingTopics;

    return !loading && courses.length === 0 ? null : (
        <>
            <MediathekSectionHeader
                text={intl.formatMessage(
                    { id: 'media-library.home.topic-courses' },
                    { topic: intl.locale === 'en-GB' ? '' : selectedTopicTitle }
                )}
            />
            {loading ? (
                <SkeletonCoursesTable />
            ) : (
                <>
                    <CoursesTable hideMoreCoursesLink courses={courses} variant="MULTIPRODUCT" />
                    {countOfCoursesThatDidntFitInTheTable > 0 && !displayAllCourses ? (
                        <Button
                            variant="secondary"
                            className={classes.seeMoreButton}
                            onClick={() => {
                                setDisplayAllCourses((prev) => !prev);
                            }}
                        >
                            {intl.formatMessage(
                                { id: 'media-library.home.see-topic-courses' },
                                { count: countOfCoursesThatDidntFitInTheTable }
                            )}
                            <Icon
                                icon="caretDown"
                                style={
                                    displayAllCourses ? { transform: 'rotate(180deg)' } : undefined
                                }
                            />
                        </Button>
                    ) : null}
                </>
            )}
        </>
    );
}
