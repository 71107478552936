import React from 'react';
import { Container, Box, Grid } from '@mui/material';
import * as yup from 'yup';
import { Button, FormikTextField, Typography, useCustomFormik } from 'components';
import { CATALOG_URLS } from 'common';

import useStyles from './Newsletter.styles';
import { newsletterData } from './Newsletter.data';
import { useRouter } from 'next/router';

export const Newsletter = () => {
    const classes = useStyles();
    const router = useRouter();
    const formik = useCustomFormik({
        initialValues: {
            email: ''
        },
        validationSchema: yup.object({
            email: yup.string().email('common.valid-email').required('common.error.required-email')
        }),
        onSubmit: async (values) => {
            const { email } = values;
            const encodedEmail = encodeURIComponent(email);
            router.push(`${CATALOG_URLS.newsletter}?email=${encodedEmail}`);
        }
    });

    return (
        <Container maxWidth="xl">
            <Box className={classes.container}>
                <Typography
                    variant="h2"
                    color="common"
                    colorVariant="white"
                    localeId="catalog.home.newsletter.title"
                />
                <Box className={classes.subtitleContainer}>
                    <Typography
                        variant="body2"
                        color="grey"
                        colorVariant="light"
                        localeId="catalog.home.newsletter.subtitle1"
                    />
                    <Typography
                        variant="body2"
                        color="grey"
                        colorVariant="light"
                        localeId="catalog.home.newsletter.subtitle2"
                    />
                </Box>
                <Grid container className={classes.itemsContainer}>
                    {newsletterData.map((item, index) => (
                        <Box className={classes.itemContainer} key={index}>
                            <img src={item.iconUrl} alt="" />
                            <Typography
                                className={classes.itemTitle}
                                localeId={item.localeId}
                                variant="textSmall"
                                fontFamily="Poppins"
                            />
                        </Box>
                    ))}
                </Grid>
                <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <Grid className={classes.fieldsContainer} container spacing={3}>
                        <FormikTextField
                            inputProps={{
                                className: classes.input
                            }}
                            name="email"
                            type="email"
                            localeId="catalog.home.newsletter.email"
                            formik={formik}
                            gridProps={{ xs: 12, md: 4 }}
                            hideClearIcon
                            required={false}
                        />
                        <Grid item xs={12} md={2}>
                            <Button
                                className={classes.submitButton}
                                type="submit"
                                disabled={formik.isSubmitting}
                                color="secondary"
                                localeId="catalog.home.newsletter.subscribe"
                            />
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};
