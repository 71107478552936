import React from 'react';
import { ContentsByTopics } from './ContentsByTopics/ContentsByTopics';
import { MediathekSectionHeader } from './MediathekSectionHeader';
import { MediathekLectureItem } from '../../components/MediathekLectureItem/MediathekLectureItem';
import { useIntl } from 'react-intl';
import { showFreeBadge, useMembershipContext } from 'common';

type LecturesSectionProps = {
    selectedTopicCode: string;
};

export const LecturesSection = ({ selectedTopicCode }: LecturesSectionProps) => {
    const { formatMessage } = useIntl();
    const { isMember } = useMembershipContext();

    return (
        <>
            <MediathekSectionHeader text={formatMessage({ id: 'catalog.home.lectures' })} />
            <ContentsByTopics
                renderItem={(item, index) => (
                    <MediathekLectureItem
                        lecture={item}
                        isFree={showFreeBadge({
                            contentIsFree: item.isFree,
                            parentContentIsFree: item.parent?.isFree,
                            isMember
                        })}
                        index={index}
                    />
                )}
                selectedTopicCode={selectedTopicCode}
            />
        </>
    );
};
