import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            margin: theme.spacing(6, 3),
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: 12,
            padding: theme.spacing(4, 3, 4, 3),
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(6, 0)
            }
        },
        subtitleContainer: {
            margin: theme.spacing(4, 0, 5, 0),
            textAlign: 'center'
        },
        itemsContainer: {
            flexWrap: 'nowrap',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'flex'
            }
        },
        itemContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: theme.spacing(4),
            '& > svg': {
                width: 48
            }
        },
        itemTitle: {
            marginTop: theme.spacing(2),
            color: theme.palette.common.white,
            fontWeight: 600
        },
        buttonContainer: {
            paddingTop: 0
        },
        submitButton: {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(0, 2),
                height: 56,
                borderRadius: 6
            }
        },
        form: {
            width: '100%'
        },
        fieldsContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        input: {
            color: theme.palette.common.white,
            '&:focus': {
                color: theme.palette.common.black
            },
            '&:hover': {
                color: theme.palette.common.black
            }
        },
        checkIcon: {
            color: theme.palette.success.main,
            width: 32,
            height: 32,
            marginLeft: theme.spacing(1)
        },
        addedToNewsletterContainer: {
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center'
        },
        addedToNewsletter: {
            color: theme.palette.common.white
        }
    }),
    { name: 'Newsletter', index: 10 }
);

export default useStyles;
