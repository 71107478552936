import React from 'react';

import { AspectRatio } from 'common';
import { Typography } from 'components';
import { Box } from '@mui/material';
import useStyles from './LectureThumbnail.styles';
import { buildImageURL } from '../../utils/buildImageURL';
import {
    VIDEO_THUMBNAIL_HEIGHT,
    VIDEO_THUMBNAIL_WIDTH
} from '../MediathekLectureItem/VideoThumbnail';

type LectureThumbnailProps = {
    thumbnailUrl: string | null | undefined;
    duration?: string | null;
};

export const LectureThumbnail = (props: LectureThumbnailProps) => {
    const { thumbnailUrl, duration } = props;
    const classes = useStyles();

    const thumbnailUrlCompressed =
        thumbnailUrl &&
        buildImageURL(thumbnailUrl, {
            width: VIDEO_THUMBNAIL_WIDTH,
            height: VIDEO_THUMBNAIL_HEIGHT
        });

    return (
        <Box className={classes.container} data-testid="lecture-thumbnail">
            <AspectRatio>
                {thumbnailUrlCompressed ? (
                    <img
                        src={thumbnailUrlCompressed}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: 3 }}
                    />
                ) : (
                    <div className={classes.fallbackContainer} />
                )}
            </AspectRatio>
            {duration && <Typography className={classes.duration} title={duration} />}
        </Box>
    );
};
