import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2)
            }
        },
        cardListItem: {
            minWidth: 260,
            padding: theme.spacing(0, 1),

            [theme.breakpoints.up('md')]: {
                minWidth: 360
            }
        },
        card: {
            width: '100%',
            height: '100%',
            borderRadius: 16,
            ...theme.border({ color: theme.palette.secondary[100], radius: 16 }),
            background: theme.palette.common.white
        },
        titleContainer: {
            flexBasis: '100%',
            padding: theme.spacing(0, 2),

            [theme.breakpoints.up('md')]: {
                flexBasis: '50%',
                order: 0,
                marginBottom: theme.spacing(0),
                padding: theme.spacing(0)
            },

            [theme.breakpoints.up('lg')]: {
                flexBasis: '65%'
            }
        },
        title: {
            textAlign: 'center',

            [theme.breakpoints.up('md')]: {
                textAlign: 'start'
            }
        },
        horizontalListContainer: {
            maxWidth: theme.breakpoints.values.xl,
            [theme.breakpoints.up('lg')]: {
                marginLeft: theme.spacing(-3)
            },
            [theme.breakpoints.down('sm')]: {
                width: '100vw'
            }
        }
    }),
    {
        name: 'MediathekStartedOnDemandCourses',
        // index to override card and Horizontal List styles
        index: 11
    }
);

export default useStyles;
