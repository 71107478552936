import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    getCountryCode3,
    hasGroupMembership,
    Types,
    useMembershipContext,
    useUserContext
} from 'common';

import MediathekAddons from './UserWelcomeHeader/MediathekAddons';
import { useRecentlyStartedCoursesQuery } from '../../../graphql/catalog/queries/queries.generated';
import MediathekStartedOnDemandCourses from './MediathekStartedOnDemandCourses';
import UserWelcomeHeader from './UserWelcomeHeader/UserWelcomeHeader';
import { useIsOnDemandAccreditationEnabled } from '../../../hooks/useIsOnDemandAccreditationEnabled';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    addonsContainer: {
        width: '100%',
        flexBasis: '100%',
        marginBottom: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            order: 2,
            marginTop: 0,
            marginBottom: theme.spacing(7)
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2)
        }
    }
}));

export const MediathekHeader = () => {
    const classes = useStyles();
    const { isLoading: isLoadingUser } = useUserContext();
    const { onDemandAccreditationEnabled } = useIsOnDemandAccreditationEnabled();
    const { locale } = useIntl();
    const { userMembership, loading: isLoadingMembership } = useMembershipContext();

    const { data: coursesSyfatSyrotData } = useRecentlyStartedCoursesQuery({
        variables: {
            contentType: [Types.ContentType.OndemandCourse],
            brandType: [Types.ProductBrand.Syfat, Types.ProductBrand.Syrot],
            country: [getCountryCode3(locale)]
        },
        skip: !onDemandAccreditationEnabled
    });
    const { data: coursesData } = useRecentlyStartedCoursesQuery({
        variables: {
            contentType: [Types.ContentType.OndemandCourse],
            brandType: [Types.ProductBrand.OndemandAccredited],
            country: [getCountryCode3(locale)]
        },
        skip: !onDemandAccreditationEnabled
    });
    const userHasGroupMembership = hasGroupMembership(userMembership);
    const hasStartedSomeOnDemandCourse = Boolean(
        onDemandAccreditationEnabled &&
            (coursesData?.recentlyStartedCourses?.length ||
                coursesSyfatSyrotData?.recentlyStartedCourses?.length)
    );

    if (isLoadingUser || isLoadingMembership) {
        return null;
    }

    if (userHasGroupMembership || hasStartedSomeOnDemandCourse) {
        return (
            <>
                <UserWelcomeHeader />
                {userHasGroupMembership && <MediathekAddons className={classes.addonsContainer} />}
                {hasStartedSomeOnDemandCourse && (
                    <MediathekStartedOnDemandCourses className={classes.addonsContainer} />
                )}
            </>
        );
    }

    return <UserWelcomeHeader />;
};
