import React from 'react';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Icon } from 'components';
import classnames from 'classnames';
import { NextLink } from 'common';

const useStyles = makeStyles((theme) => ({
    sectionIcon: {
        height: 38,
        width: 38,
        aspectRatio: '1',
        marginInlineEnd: 8,
        userSelect: 'none'
    },
    styledHeader: {
        ...theme.typography.h2,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24,
        alignItems: 'center',
        color: theme.palette.primary.main,
        marginBlockStart: 50,
        marginBlockEnd: 30,
        marginInline: 24,

        [theme.breakpoints.down('sm')]: {
            marginBlockStart: 30,
            marginBlockEnd: 15,
            marginInline: 16
        }
    },
    headerIconContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    seeMoreUrl: {
        ...theme.typography.linkXSmall,
        color: theme.palette.secondary.main,
        fontFamily: 'Roboto',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        lineHeight: 'normal',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

interface MediathekSectionHeaderProps {
    text: string;
    iconUrl?: string;
    seeMoreUrl?: string | null;
    headerClassname?: string;
    'data-testid'?: string;
}

export const MediathekSectionHeader = (props: MediathekSectionHeaderProps) => {
    const { iconUrl, text, seeMoreUrl, headerClassname, ...rest } = props;
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <h2 className={classnames([classes.styledHeader, headerClassname])} {...rest}>
            <div className={classes.headerIconContainer}>
                {iconUrl && <img src={iconUrl} className={classes.sectionIcon} alt="" />}
                {text}
            </div>
            {seeMoreUrl && (
                <NextLink href={seeMoreUrl} className={classes.seeMoreUrl}>
                    {formatMessage({ id: 'catalog.home.section-title.show-all' })}
                    <Icon icon="arrowRight" />
                </NextLink>
            )}
        </h2>
    );
};
