import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Typography } from 'components';

import useStyles from './AddonContentProgress.styles';
import classnames from 'classnames';

type Progresses = Array<{
    contentProgress?:
        | {
              completed?: boolean | null | undefined;
          }
        | null
        | undefined;
    questions?: Array<{
        passed?: {
            passedAt?: string | null;
        } | null;
    }> | null;
}>;

const getProgress = (schedule: Progresses = [], variant: 'QUESTIONS' | 'PROGRESS') => {
    let numberOfCompletedLectures;
    if (variant === 'PROGRESS') {
        numberOfCompletedLectures = schedule.filter(
            (schedule) => schedule.contentProgress?.completed
        ).length;
    } else {
        numberOfCompletedLectures = schedule.filter((sc) =>
            sc.questions?.every((q) => q.passed?.passedAt)
        ).length;
    }

    const numberOfSchedules = schedule.length || 1;

    return Math.trunc(100 * (numberOfCompletedLectures / numberOfSchedules));
};

type Props = {
    progress: number | Progresses;
    className?: string;
    variant: 'QUESTIONS' | 'PROGRESS';
};

const AddonContentProgress = (props: Props) => {
    const { progress, className, variant } = props;
    const classes = useStyles();
    const progressN = typeof progress === 'number' ? progress : getProgress(progress, variant);
    return (
        <Grid
            item
            display="flex"
            className={classnames(classes.container, className)}
            alignItems="center"
        >
            <CircularProgress
                classes={{
                    root: classes.progress,
                    svg: classes.svg
                }}
                // we could not update the width and height using classes
                size={28}
                thickness={7}
                value={progressN}
                variant="determinate"
                color="secondary"
            />
            <Typography
                variant="h6"
                localeId="common.addon-card.progress"
                localeValues={{ progress: progressN }}
                color="secondary"
            />
        </Grid>
    );
};

export default AddonContentProgress;
