import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            display: 'flex',
            gap: theme.spacing(1.5),
            whiteSpace: 'nowrap',

            [theme.breakpoints.up('sm')]: {
                gap: theme.spacing(2)
            }
        },

        progress: {
            position: 'relative',
            flexShrink: 0,

            '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                ...theme.border({
                    width: 5,
                    color: theme.palette.body[200],
                    radius: '50%'
                })
            }
        },
        svg: {
            // make it draw over ":before" element
            position: 'relative',
            zIndex: 1
        }
    }),
    { name: 'AddonContentProgress' }
);

export default useStyles;
