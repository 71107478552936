import React from 'react';
import { Box } from '@mui/material';
import {
    MembershipTitle,
    Types,
    useMembershipContext,
    UserCompanyLogo,
    useUserContext,
    useUserFullName
} from 'common';
import { Icon, Typography } from 'components';

import useStyles from './UserWelcomeHeader.styles';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

const UserWelcomeHeader = () => {
    const { locale } = useIntl();
    const isCMED = locale === 'en-GB';
    const { user, isLoggedIn } = useUserContext();
    const { userMembership } = useMembershipContext();
    const hasGroupMembership = Boolean(userMembership?.addedToGroupMembershipDateTime);
    const { name: companyName, logoUrl: companyLogoUrl } = user?.company || {};
    const userFullName = useUserFullName(user);
    const isPha = user?.accountType === Types.AccountType.Pha;
    const classes = useStyles({ isCMED });

    return (
        <Box
            className={classNames(hasGroupMembership ? classes.groupContainer : classes.container)}
        >
            {hasGroupMembership && (
                <Box className={classes.companyContainer}>
                    <UserCompanyLogo companyLogo={companyLogoUrl} className={classes.companyLogo} />
                    <Typography variant="h3" title={companyName} className={classes.companyName} />
                </Box>
            )}
            <Box className={classes.titleContainer}>
                {isLoggedIn ? (
                    <>
                        <Typography
                            localeId="catalog.mediathek.addons.title"
                            className={classes.title}
                            variant="h1"
                            color="primary"
                        />
                        {!isPha && (
                            <Typography variant="h4" className={classes.subtitle}>
                                {userFullName}
                            </Typography>
                        )}
                    </>
                ) : (
                    <>
                        <Typography
                            localeId="catalog.mediathek.home.guest.title"
                            className={classes.title}
                            variant="h1"
                            color="primary"
                        />
                        <Typography
                            localeId="catalog.mediathek.home.guest.subtitle"
                            variant="h4"
                            className={classes.subtitle}
                        />
                    </>
                )}
                {userMembership && hasGroupMembership && (
                    <Box display="flex" className={classes.membershipContainer}>
                        <Icon icon="success" className={classes.membershipIcon} />
                        <MembershipTitle
                            name={userMembership.membership.name}
                            membershipType={userMembership.membership.membershipType}
                            className={classes.membershipTitle}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default UserWelcomeHeader;
