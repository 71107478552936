import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { catalogTracking } from '../../../utils/segment';
import { MEDIATHEK_URLS, formatters, getCountryCode, useIntersectionObserver } from 'common';
import { HorizontalList } from 'components';
import { TopicRendererProps } from './TopicRenderer.types';
import { TopicTitle } from './TopicTitle';
import useStyles from './TopicRenderer.styles';
import { LECTURES_PER_PAGE } from '../MediathekHome.utils';
import {
    CONTENT_TYPE_QUERY_PARAM_NAME,
    ContentContentTypeFilter
} from '../../../components/Filters/Filters.types';
import {
    MediathekVideosQuery,
    useMediathekVideosLazyQuery
} from '../../../graphql/catalog/queries';
import SkeletonMediathekVideoItem from '../../../components/MediathekLectureItem/SkeletonMediathekLectureItem';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTopicParam } from '../../../utils/topics';
import { getHomePageTopicVideosQuery } from './queries/HomePageTopicVideosQuery';
import { getHomePageTopicVideosQueryUK } from './queries/HomePageTopicVideosQueryUK';

export type MediathekVideo = MediathekVideosQuery['contentDocuments']['data'][number];

const TopicRenderer = memo((props: TopicRendererProps): React.ReactElement | null => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { locale } = useIntl();
    const listId = catalogTracking.useGenerateListIdFromRoute();
    useIntersectionObserver(containerRef, {}, () =>
        catalogTracking.contentListViewed(videosData, `${listId}-lectures-${topic.code}`, locale)
    );
    const { renderItem, topic, isMainTopic, childCodes, language } = props;
    const classes = useStyles();
    const countryCode = getCountryCode(locale);
    const theme = useTheme();
    const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'));
    const [videosOffset, setVideosOffset] = useState(0);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [videosData, setVideosData] = useState<Array<MediathekVideo>>([]);
    const [totalVideosAvailable, setTotalVideosAvailable] = useState(0);
    // This query was running in the loop without nextFetchPolicy: 'cache-only'
    const [fetchVideos] = useMediathekVideosLazyQuery({ nextFetchPolicy: 'cache-only' });

    const handleOffsetChange = useCallback(
        async (newOffset: number) => {
            if (newOffset === 0 || isDesktopVersion) {
                setLoadingVideos(true);
            }

            const searchQuery =
                locale === 'en-GB'
                    ? getHomePageTopicVideosQueryUK({
                          countryCode,
                          pageNumber: newOffset / LECTURES_PER_PAGE,
                          topics: [topic.code],
                          isMainTopic,
                          childCodes
                      })
                    : getHomePageTopicVideosQuery({
                          countryCode,
                          pageNumber: newOffset / LECTURES_PER_PAGE,
                          topics: [topic.code],
                          isMainTopic,
                          childCodes,
                          language
                      });

            const { data } = await fetchVideos(searchQuery);
            setVideosOffset(newOffset);
            setVideosData(data?.contentDocuments.data ?? []);
            setTotalVideosAvailable(data?.contentDocuments.totalCount ?? 0);
            setLoadingVideos(false);
        },
        [topic.code, locale, language, countryCode]
    );

    useEffect(() => {
        handleOffsetChange(0);
    }, [language]);

    const { getNewTopicsToRedirect } = useTopicParam();
    const topicStr = encodeURIComponent(
        getNewTopicsToRedirect(topic.code)
            ?.map((t) => t)
            .join(',') ?? ''
    );
    const showAllUrl = `${MEDIATHEK_URLS.search}?topics=${topicStr}&${CONTENT_TYPE_QUERY_PARAM_NAME}=${ContentContentTypeFilter.LECTURE}`;

    if (videosData.length === 0 && !loadingVideos) {
        return null;
    }

    return (
        <div ref={containerRef}>
            <TopicTitle
                text={formatters.formatTranslation(topic.description, { locale })}
                showAllUrl={showAllUrl}
                iconUrl={topic.specialityIconUrl}
                iconColor={topic.iconPrimaryColor}
            />
            <HorizontalList
                classes={{ horizontalListContainer: classes.horizontalListContainer }}
                items={videosData}
                numberOfItemsInRow={LECTURES_PER_PAGE}
                offset={videosOffset}
                onOffsetChange={handleOffsetChange}
                renderItem={renderItem}
                totalCount={totalVideosAvailable}
                isSkeletonVisible={loadingVideos}
                renderSkeletonItem={() => <SkeletonMediathekVideoItem />}
            />
        </div>
    );
});

export default TopicRenderer;
