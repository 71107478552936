export const newsletterData: Array<{ localeId: string; iconUrl: string }> = [
    {
        localeId: 'catalog.home.newsletter.account',
        iconUrl: 'https://bk-fms-eu.storage.googleapis.com/public/static/icon-account.svg'
    },
    {
        localeId: 'catalog.home.newsletter.rss',
        iconUrl: 'https://bk-fms-eu.storage.googleapis.com/public/static/icon-rss.svg'
    },
    {
        localeId: 'catalog.home.newsletter.user',
        iconUrl: 'https://bk-fms-eu.storage.googleapis.com/public/static/icon-user.svg'
    },
    {
        localeId: 'catalog.home.newsletter.devices',
        iconUrl: 'https://bk-fms-eu.storage.googleapis.com/public/static/icon-devices.svg'
    }
];
