import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { MediathekVideoSpeakers } from './MediathekVideoSpeakers';
import { VIDEO_THUMBNAIL_WIDTH, VideoThumbnail } from './VideoThumbnail';
import { generateContentWithCourseURL } from '../../hooks/useOnContentClick';
import { MediathekLecture } from './MediathekLecture.types';
import { NextLink, VideoRating } from 'common';
import { catalogTracking } from '../../utils/segment';
import { useIntl } from 'react-intl';
import { TitleTooltip, Typography } from 'components';

export const useMediathekLectureItemStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: VIDEO_THUMBNAIL_WIDTH,
        marginInlineStart: theme.spacing(1.5),
        '&:hover $title': {
            textDecoration: 'underline'
        },
        ...theme.border({ color: theme.palette.body['400'], radius: 6 })
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(0.5),
        height: 150
    },
    title: {
        wordBreak: 'break-word',
        color: theme.palette.primary.main,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        marginBlock: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2
    },
    lightText: {
        color: theme.palette.label.main,
        ...theme.typography.textXSmall
    }
}));

type MediathekLectureItemProps = {
    lecture: MediathekLecture;
    isFree?: boolean;
    index: number | undefined;
    lectureDates?: string;
};

export const MediathekLectureItem = (props: MediathekLectureItemProps) => {
    const { lecture, isFree = false, index, lectureDates } = props;
    const { locale } = useIntl();
    const classes = useMediathekLectureItemStyles();
    const url = generateContentWithCourseURL(lecture);

    return (
        <NextLink
            className={classes.container}
            href={url}
            onClick={() => catalogTracking.contentClicked(lecture.contentId, { locale, index })}
            data-testid="mediathek-lecture-item"
        >
            <VideoThumbnail {...lecture} index={index} displayLockIcon={isFree} />
            <div className={classes.descriptionContainer}>
                <VideoRating rating={lecture.rating} />
                <TitleTooltip title={lecture.title}>
                    <h4 className={classes.title}>{lecture.title}</h4>
                </TitleTooltip>
                <MediathekVideoSpeakers speakers={lecture.speakers} />
                {lectureDates && <Typography className={classes.lightText} title={lectureDates} />}
            </div>
        </NextLink>
    );
};
