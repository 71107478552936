import { useIntl } from 'react-intl';
import { formatters, Types } from 'common';

export const useGetOnDemandCourseTitle = () => {
    const { formatMessage, locale } = useIntl();

    return (
        productName: Types.Scalars['JSON'] | null,
        brand: Types.ProductBrand | null | undefined
    ) => {
        const translatedProductName =
            formatters.formatTranslation(productName, {
                locale
            }) || '';
        const courseTitle = `${translatedProductName} ${
            brand !== Types.ProductBrand.Syfat
                ? formatMessage({
                      id: 'catalog.on-demand.banner.on-demand-cme-fortbildung'
                  })
                : 'SYFAT'
        }`;

        return courseTitle;
    };
};
