import React from 'react';
import { Box } from '@mui/material';
import { useIsDesktop } from 'common';
import { makeStyles } from '@mui/styles';
import { Skeleton } from 'components';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingInline: theme.spacing(3)
    },
    styledSkeleton: {
        border: `1px solid ${theme.palette.body.light}`,
        borderRadius: '6px'
    }
}));

export const SkeletonCoursesTable = () => {
    const isDesktop = useIsDesktop();
    const classes = useStyles();

    if (isDesktop) {
        return (
            <div className={classes.container}>
                <Box paddingTop={2}>
                    <Skeleton
                        className={classes.styledSkeleton}
                        variant="rectangular"
                        width="100%"
                        height={64}
                    />
                </Box>
                <Box paddingTop={2}>
                    <Skeleton
                        className={classes.styledSkeleton}
                        variant="rectangular"
                        width="100%"
                        height={64}
                    />
                </Box>
                <Box paddingTop={2}>
                    <Skeleton
                        className={classes.styledSkeleton}
                        variant="rectangular"
                        width="100%"
                        height={64}
                    />
                </Box>
                <Box paddingTop={2}>
                    <Skeleton
                        className={classes.styledSkeleton}
                        variant="rectangular"
                        width="100%"
                        height={64}
                    />
                </Box>
                <Box paddingTop={2}>
                    <Skeleton
                        className={classes.styledSkeleton}
                        variant="rectangular"
                        width="100%"
                        height={64}
                    />
                </Box>
            </div>
        );
    }

    return null;
};
