import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { MediathekVideoSpeakers } from './MediathekVideoSpeakers';
import { VideoThumbnail } from './VideoThumbnail';
import { useMediathekLectureItemStyles } from './MediathekLectureItem';
import { MediathekLecture } from './MediathekLecture.types';
import { buildPartnerContentURL } from '../../hooks/usePartnerSearchItemUrl';
import { Types, NextLink, VideoRating } from 'common';
import Image from 'next/image';
import { catalogTracking } from '../../utils/segment';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { TitleTooltip } from 'components';

const useStyles = makeStyles(() => ({
    partnerLogo: {
        objectFit: 'scale-down',
        objectPosition: 'right'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}));

type MediathekPartnerVideoItemProps = {
    lecture: MediathekLecture & {
        sponsorships: Array<{
            sponsorCompany?: {
                id: Types.CompanyDocument['id'];
                logoUrl?: Types.CompanyDocument['logoUrl'];
                name?: Types.CompanyDocument['name'];
                slug?: Types.CompanyDocument['slug'];
                publishStatus?: Types.CompanyDocument['publishStatus'];
            } | null;
        }> | null;
    };
    isFree?: boolean;
    index: number | undefined;
};

/**
 * This component extends the MediathekLectureItem component and adds the ability to show the partner logo in the video item.
 */
export const MediathekPartnerVideoItem = (props: MediathekPartnerVideoItemProps) => {
    const { lecture, isFree = false, index } = props;
    const { locale } = useIntl();
    const classes = useStyles();
    const sharedVideoItemClasses = useMediathekLectureItemStyles();

    const sponsorCompany = lecture.sponsorships?.find(
        (sponsorship) => sponsorship.sponsorCompany?.publishStatus === 'PUBLISHED'
    )?.sponsorCompany;
    const url = buildPartnerContentURL({
        slug: sponsorCompany?.slug,
        providerId: sponsorCompany?.id,
        contentId: lecture.contentId
    });

    return (
        <NextLink
            className={sharedVideoItemClasses.container}
            href={url}
            onClick={() => catalogTracking.contentClicked(lecture.contentId, { locale, index })}
        >
            <VideoThumbnail {...lecture} displayLockIcon={isFree} index={index} />
            <div
                className={classnames(sharedVideoItemClasses.descriptionContainer, classes.content)}
                style={{ height: 200 }}
            >
                <div>
                    <VideoRating rating={lecture.rating} />
                    <TitleTooltip title={lecture.title}>
                        <h4 className={sharedVideoItemClasses.title}>{lecture.title}</h4>
                    </TitleTooltip>
                    <MediathekVideoSpeakers speakers={lecture.speakers} />
                </div>
                <Image
                    src={sponsorCompany?.logoUrl || ''}
                    className={classes.partnerLogo}
                    sizes="100vw"
                    height={0}
                    width={0}
                    style={{ width: '100%', height: 45 }}
                    alt={sponsorCompany?.name || ''}
                />
            </div>
        </NextLink>
    );
};
