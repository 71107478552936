import { Types } from 'common';
import { LECTURES_PER_PAGE } from '../../MediathekHome.utils';
import getContentsDefinitionStartDateFilter from '../../../../utils/queries/getContentsDefinitionStartDateFilter';
import { ContentContentTypeFilter } from '../../../../components/Filters';

type GetHomePageBrandVideosQuery = {
    countryCode: string;
    pageNumber: number;
    topicCode: string;
    brand: typeof Types.ProductBrand.Summedup | typeof Types.ProductBrand.Skill;
};

export const getHomePageBrandVideosQuery = ({
    countryCode,
    pageNumber,
    topicCode,
    brand
}: GetHomePageBrandVideosQuery) => ({
    variables: {
        searchQuery: {
            pageNumber,
            pageSize: LECTURES_PER_PAGE,
            filters: [
                {
                    field: 'publishedDomains',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [countryCode]
                },
                {
                    field: 'contentType',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [Types.ContentType.Lecture]
                },
                {
                    field: 'brandType',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [brand]
                },
                ...(getContentsDefinitionStartDateFilter(ContentContentTypeFilter.LECTURE) ?? [])
            ],
            orders: [`topics.priorities.${topicCode}`, '-startDateTime']
        }
    }
});
