/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import classnames from 'classnames';
import { Box } from '@mui/material';
import AddonCard from '../../../components/AddonCard/AddonCard';
import AddonCardSkeleton from '../../../components/AddonCard/AddonCard.Skeleton';
import {
    booleanFilter,
    getAddonCatalogLink,
    getCountryCode3,
    Types,
    useMembershipContext,
    useUserContext
} from 'common';
import { HorizontalList } from 'components';

import useStyles from './MediathekStartedOnDemandCourses.styles';
import { useRecentlyStartedCoursesQuery } from '../../../graphql/catalog/queries';
import { useGetOnDemandCourseTitle } from '../../Content/OnDemand/OnDemandContent.utils';
import { useIntl } from 'react-intl';

type Props = {
    className?: string;
};

const MediathekStartedOnDemandCourses = (props: Props) => {
    const { className } = props;
    const userCtx = useUserContext();
    const { user } = userCtx;
    const { locale } = useIntl();
    const { isMember } = useMembershipContext();
    const [offset, setOffset] = useState(0);
    const classes = useStyles();
    const { data, loading } = useRecentlyStartedCoursesQuery({
        variables: {
            contentType: [Types.ContentType.OndemandCourse],
            brandType: [Types.ProductBrand.OndemandAccredited],
            country: [getCountryCode3(locale)]
        }
    });
    const getOnDemandCourseTitle = useGetOnDemandCourseTitle();
    const courses = (data?.recentlyStartedCourses || [])
        .map((coursesData) => ({ ...coursesData, id: coursesData?.course?.id ?? '' }))
        .filter((coursesData) => booleanFilter(coursesData.course));

    if (!user || !isMember) {
        return null;
    }

    const pageSize = 3;
    const visibleList = courses.slice(offset, offset + pageSize);
    const isLoading = loading;
    return (
        <Box className={classnames(classes.container, className)}>
            <Box>
                <HorizontalList
                    items={visibleList}
                    renderItem={(data) => {
                        const progressLink = `/ondemand-kurs/${data.course?.contentId}`;
                        const currentVideo = data.lastWatchedVideo;
                        const courseLink = getAddonCatalogLink(
                            data.course?.contentId ?? '',
                            currentVideo?.contentId
                        );
                        const courseTitle = getOnDemandCourseTitle(
                            data.course?.product?.name,
                            data.course?.brand
                        );
                        return (
                            <AddonCard
                                completedPercent={data.completedPercent}
                                lastWatchedVideo={data.lastWatchedVideo}
                                doesSyrotHaveTests={false}
                                title={courseTitle}
                                fullContent={data.course!}
                                className={classes.card}
                                isCompact
                                progressLink={progressLink}
                                courseLink={courseLink}
                            />
                        );
                    }}
                    numberOfItemsInRow={pageSize}
                    totalCount={courses.length}
                    offset={offset}
                    onOffsetChange={setOffset}
                    classes={{
                        horizontalListContainer: classes.horizontalListContainer,
                        item: classes.cardListItem
                    }}
                    renderSkeletonItem={() => (
                        <AddonCardSkeleton className={classes.card} isCompact />
                    )}
                    isSkeletonVisible={isLoading}
                />
            </Box>
        </Box>
    );
};

export default MediathekStartedOnDemandCourses;
