import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { catalogTracking } from '../../utils/segment';
import {
    booleanFilter,
    CATALOG_URLS,
    getCountryCode,
    getCountryCode3,
    Queries,
    Types,
    useIntersectionObserver,
    useIsDesktop,
    useRouter,
    useUserContext,
    MEDIATHEK_URLS,
    showFreeBadge,
    useMembershipContext,
    formatters
} from 'common';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { DateFormat, formatDateForDB, HorizontalList, parseDBDate, SectionsMenu } from 'components';
import { dateFilterMenuOptions, DateFilterOptionEnum } from '../../utils/filters/dateFilter';
import SkeletonWebUpItem from '../../components/WebUpItem/SkeletonWebUpItem';
import { ContentContentTypeFilter } from '../../components/Filters/Filters.types';
import WebUpItem from '../../components/WebUpItem/WebUpItem';
import { EmptySection } from './EmptySection/EmptySection';
import { ACTIVE_TOPIC_CODE } from '../../utils/topics';
import {
    GetRecommendedContentsQuery,
    MediathekPartnerforumContentsQuery,
    useGetRecentlyWatchedContentsQuery,
    useGetRecommendedContentsLazyQuery,
    useGetUserSpecialitiesLazyQuery,
    useMediathekPartnerforumContentsLazyQuery,
    useMediathekVideosLazyQuery
} from '../../graphql/catalog/queries';
import useStyles from './MediathekHome.styles';
import {
    getContentTypeDateQueryParams,
    LECTURES_PER_PAGE,
    ratingThresholdFilter,
    WEBUPS_PER_PAGE
} from './MediathekHome.utils';
import getContentSearchQuery from '../../utils/queries/getContentSearchQuery';
import { useIsContentAvailable } from '../../hooks';
import { MediathekLectureItem } from '../../components/MediathekLectureItem/MediathekLectureItem';
import LectureItemWithProgress from '../../components/LectureItemWithProgress/LectureItemWithProgress';
import { MediathekSectionHeader } from './MediathekSectionHeader';
import SkeletonMediathekLectureItem from '../../components/MediathekLectureItem/SkeletonMediathekLectureItem';
import { MediathekVideo } from './ContentsByTopics/TopicRenderer';
import { MediathekPartnerVideoItem } from '../../components/MediathekLectureItem/MediathekPartnerVideoItem';
import { getHomePageBrandVideosQuery } from './ContentsByTopics/queries/HomePageBrandVideosQuery';

type WebupsProps = {
    selectedTopicCode: string;
};

export const Webups = ({ selectedTopicCode }: WebupsProps) => {
    const classes = useStyles();
    const { locale, formatMessage } = useIntl();
    const countryCode = getCountryCode(locale);
    const horizontalListClasses = { horizontalListContainer: classes.horizontalListContainer };
    const [contentDocuments] = Queries.useContentDocumentsLazyQuery();
    const isContentAvailable = useIsContentAvailable();
    const webupListId = `${catalogTracking.useGenerateListIdFromRoute()}-webups`;
    const listRef = useRef<HTMLDivElement | null>(null);
    useIntersectionObserver(listRef, {}, () =>
        catalogTracking.contentListViewed(currentWebups, webupListId, locale)
    );
    const router = useRouter<{ webupsSearch?: DateFilterOptionEnum }>();
    const {
        query: { webupsSearch = DateFilterOptionEnum.FORTHCOMING },
        setQuery
    } = router;
    const [webupsOffset, setWebupsOffset] = useState(0);
    const [loadingWebups, setLoadingWebups] = useState(true);
    const [webupsData, setWebupsData] = useState<Queries.ContentDocumentsQuery | null>();
    const currentWebups = webupsData?.contentDocuments?.data || [];
    const [selectedWebUpTopicCode, setSelectedWebUpTopicCode] = useState(selectedTopicCode);
    const [reorderedWebUps, setReorderedWebUps] =
        useState<Queries.ContentDocumentsQuery['contentDocuments']['data']>(currentWebups);

    const [isWebupDataAvailable, setIsWebupDataAvailable] = useState(false);
    const theme = useTheme();
    const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'));
    const homeWebupsListId = `${catalogTracking.useGenerateListIdFromRoute()}-webups`;

    const handleWebupsDateChange = useCallback(
        (dateFilterOption: string = DateFilterOptionEnum.FORTHCOMING) => {
            if (dateFilterOption !== webupsSearch) {
                setWebupsOffset(0);
                setWebupsData(null);
                setQuery({ webupsSearch: dateFilterOption as DateFilterOptionEnum });
            }
        },
        [webupsSearch]
    );

    const resetWebups = () => {
        setWebupsData(null);
        loadAllWebups();
    };

    const loadAllWebups = useCallback(async () => {
        setLoadingWebups(true);
        const queryParamsWithoutDate = {
            contentType: Types.ContentType.Webinar,
            pageNumber: 0,
            pageSize: 100,
            topicCodes: [selectedWebUpTopicCode, ACTIVE_TOPIC_CODE],
            countryCode
        };
        const query = getContentSearchQuery({
            ...queryParamsWithoutDate,
            filters: [ratingThresholdFilter],
            date: webupsSearch
        });
        const isDataAvailable = await isContentAvailable(
            getContentSearchQuery({
                ...queryParamsWithoutDate,
                filters: [ratingThresholdFilter]
            })
        );
        setIsWebupDataAvailable(isDataAvailable);
        const { data } = await contentDocuments(query);
        const newWebups = data?.contentDocuments?.data ?? [];
        if (currentWebups.length > 0) {
            // track updated list view since user now sees list with more items, excluding first data load
            catalogTracking.contentListViewed(newWebups, homeWebupsListId, locale);
        }
        setWebupsData({
            contentDocuments: {
                ...webupsData?.contentDocuments,
                ...data?.contentDocuments,
                data: newWebups
            }
        });
        const possibleFeaturedWebUps = newWebups
            .filter((webUp) => webUp.topics?.some((t) => t.code === selectedWebUpTopicCode))
            .sort((a, b) => {
                const aStartDate = parseDBDate(a.startDateTime);
                const bStartDate = parseDBDate(b.startDateTime);

                return aStartDate.diff(bStartDate);
            });

        const featuredWebUp = possibleFeaturedWebUps[0];
        const _reorderedWebUps = featuredWebUp
            ? [featuredWebUp, ...newWebups.filter((webUp) => webUp.id !== featuredWebUp.id)]
            : newWebups;

        setReorderedWebUps(_reorderedWebUps);
    }, [webupsSearch, countryCode, selectedWebUpTopicCode]);

    useEffect(() => {
        setSelectedWebUpTopicCode(selectedTopicCode);
    }, [selectedTopicCode]);

    useEffect(() => {
        setLoadingWebups(false);
    }, [reorderedWebUps]);

    useEffect(() => {
        resetWebups();
    }, [webupsSearch, selectedTopicCode, selectedWebUpTopicCode]);

    const totalWebups = webupsData?.contentDocuments.totalCount || 0;

    if (!isWebupDataAvailable && !loadingWebups) {
        return null;
    }

    const showAllUrl = `${MEDIATHEK_URLS.search}?${getContentTypeDateQueryParams(
        ContentContentTypeFilter.WEBINAR,
        webupsSearch
    )}`;

    return (
        <Box>
            <Container maxWidth="xl" className={classes.wrappedContainer}>
                <MediathekSectionHeader
                    iconUrl="https://bk-fms-eu.storage.googleapis.com/public/static/icon-webup.svg"
                    text={formatMessage({ id: 'catalog.home.webinars' })}
                    seeMoreUrl={showAllUrl}
                />
                <div className={classes.sectionMenuContainer}>
                    <SectionsMenu
                        menus={dateFilterMenuOptions}
                        onChange={handleWebupsDateChange}
                        selection={webupsSearch}
                        loading={loadingWebups}
                    />
                </div>
            </Container>
            <HorizontalList
                classes={horizontalListClasses}
                items={reorderedWebUps.slice(webupsOffset, webupsOffset + WEBUPS_PER_PAGE) || []}
                numberOfItemsInRow={WEBUPS_PER_PAGE}
                offset={webupsOffset}
                onOffsetChange={(newWebupsOffset) => setWebupsOffset(newWebupsOffset)}
                renderItem={(webUp, index) => (
                    <WebUpItem
                        webup={webUp}
                        index={index}
                        isFeatured={index === 0 && (!isDesktopVersion || webupsOffset === 0)}
                        selectedTopicCode={selectedWebUpTopicCode}
                    />
                )}
                totalCount={totalWebups}
                isSkeletonVisible={loadingWebups}
                renderSkeletonItem={() => <SkeletonWebUpItem />}
                renderEmptyList={EmptySection}
                listRef={listRef}
            />
        </Box>
    );
};

type BrandContentsListProps = {
    topicCode: string;
    brand: typeof Types.ProductBrand.Summedup | typeof Types.ProductBrand.Skill;
};

export const BrandContentsList = (props: BrandContentsListProps) => {
    const { topicCode, brand } = props;
    const classes = useStyles();
    const horizontalListClasses = { horizontalListContainer: classes.horizontalListContainer };
    const [fetchVideos] = useMediathekVideosLazyQuery();
    const { locale, formatMessage } = useIntl();
    const countryCode = getCountryCode(locale);
    const listRef = useRef<HTMLDivElement | null>(null);
    const homeBrandListId = `${catalogTracking.useGenerateListIdFromRoute()}-${brand}`;
    useIntersectionObserver(listRef, {}, () =>
        catalogTracking.contentListViewed(videosData, homeBrandListId, locale)
    );

    const [offset, setOffset] = useState(0);
    const [loadingVideos, setLoadingVideos] = useState(true);
    const [videosData, setVideosData] = useState<Array<MediathekVideo>>([]);
    const [totalVideosAvailable, setTotalVideosAvailable] = useState(0);

    const theme = useTheme();
    const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'));

    const handleOffsetChange = useCallback(
        async (newOffset: number) => {
            if (newOffset === 0 || isDesktopVersion) {
                setLoadingVideos(true);
            }
            setOffset(newOffset);

            const query = getHomePageBrandVideosQuery({
                countryCode,
                pageNumber: newOffset / LECTURES_PER_PAGE,
                topicCode,
                brand: brand
            });

            const { data } = await fetchVideos(query);
            const newItems = data?.contentDocuments?.data ?? [];
            setVideosData(newItems);
            setTotalVideosAvailable(data?.contentDocuments.totalCount ?? 0);
            setLoadingVideos(false);
        },
        [topicCode, isDesktopVersion, countryCode]
    );

    useEffect(() => {
        handleOffsetChange(0);
    }, [topicCode]);

    const { isMember } = useMembershipContext();

    if (videosData.length === 0 && !loadingVideos) {
        return null;
    }

    const showAllUrl = `${MEDIATHEK_URLS.search}?${getContentTypeDateQueryParams(
        brand === Types.ProductBrand.Summedup
            ? ContentContentTypeFilter.SUMMEDUP
            : ContentContentTypeFilter.SKILL
    )}`;

    const summedUpTranslation = formatMessage({ id: 'catalog.home.the-minutes-update' });
    const skillTranslation = formatMessage({ id: 'catalog.home.learn-skills' });
    const headerTranslation =
        brand === Types.ProductBrand.Summedup ? summedUpTranslation : skillTranslation;

    const iconUrl =
        brand === Types.ProductBrand.Summedup
            ? 'https://bk-fms-eu.storage.googleapis.com/public/static/icon-summedup.svg'
            : 'https://bk-fms-eu.storage.googleapis.com/public/static/icon-skills.svg';

    return (
        <Box data-testid={`${brand}-container`}>
            <Container maxWidth="xl" className={classes.wrappedContainer}>
                <MediathekSectionHeader
                    iconUrl={iconUrl}
                    text={headerTranslation}
                    seeMoreUrl={showAllUrl}
                />
            </Container>
            <HorizontalList
                classes={horizontalListClasses}
                items={videosData}
                numberOfItemsInRow={LECTURES_PER_PAGE}
                offset={offset}
                onOffsetChange={handleOffsetChange}
                renderItem={(item, index) => {
                    const lectureDates =
                        brand === Types.ProductBrand.Summedup && item.startDateTime
                            ? formatters.formatDate(
                                  dayjs(item.startDateTime),
                                  DateFormat.dayMonthYear
                              )
                            : undefined;
                    return (
                        <MediathekLectureItem
                            lecture={item}
                            isFree={showFreeBadge({
                                contentIsFree: item.isFree,
                                parentContentIsFree: item.parent?.isFree,
                                isMember
                            })}
                            index={index}
                            lectureDates={lectureDates}
                        />
                    );
                }}
                totalCount={totalVideosAvailable}
                isSkeletonVisible={loadingVideos}
                renderSkeletonItem={() => <SkeletonMediathekLectureItem />}
                listRef={listRef}
            />
        </Box>
    );
};

type ParterForumContentsListProps = {
    selectedTopicCode: string;
};

export const PartnerForumContentsList = ({ selectedTopicCode }: ParterForumContentsListProps) => {
    const classes = useStyles();
    const horizontalListClasses = { horizontalListContainer: classes.horizontalListContainer };
    const [fetchContents] = useMediathekPartnerforumContentsLazyQuery();
    const { locale, formatMessage } = useIntl();
    const countryCode = getCountryCode(locale);
    const theme = useTheme();
    const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'));
    const listRef = useRef<HTMLDivElement | null>(null);
    const homePartnerForumListId = `${catalogTracking.useGenerateListIdFromRoute()}-partner-forum`;
    useIntersectionObserver(listRef, {}, () =>
        catalogTracking.contentListViewed(
            currentItems.map((item) => ({ ...item, specialities: [] })),
            homePartnerForumListId,
            locale
        )
    );

    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<MediathekPartnerforumContentsQuery | null>();

    const currentItems = data?.contentDocuments?.data || [];
    const isDesktop = useIsDesktop();
    const handlePartnerContentsOffsetChange = useCallback(
        async (newOffset: number) => {
            if (newOffset === 0 || isDesktopVersion) {
                setLoading(true);
            }
            setOffset(newOffset);
            const searchQuery = getContentSearchQuery({
                filters: [
                    {
                        field: 'isAvailableOnHomepage',
                        operation: Types.SearchFilterOperation.Equal,
                        value: ['true']
                    },
                    {
                        field: 'validDate',
                        operation: Types.SearchFilterOperation.GreaterThan,
                        value: [formatDateForDB(dayjs.utc().startOf('hour'))]
                    },
                    {
                        field: 'sponsoredByPublishStatus',
                        value: [Types.PublishStatusType.Published],
                        operation: Types.SearchFilterOperation.Equal
                    },
                    {
                        field: 'contentType',
                        value: [Types.ContentType.Lecture],
                        operation: Types.SearchFilterOperation.Equal,
                        dataType: Types.SearchFilterDataType.Enum
                    },
                    {
                        field: 'sponsoredByCountry',
                        value: [getCountryCode3(locale)],
                        operation: Types.SearchFilterOperation.Contain
                    },
                    ratingThresholdFilter
                ],
                pageSize: LECTURES_PER_PAGE,
                pageNumber: newOffset / LECTURES_PER_PAGE,
                countryCode,
                ignoreDateFilters: true
            });
            searchQuery.variables.searchQuery.orders = [
                `priorities.${selectedTopicCode}`,
                '-startDateTime'
            ];

            const response = await fetchContents(searchQuery);

            const newItems = response.data?.contentDocuments?.data ?? [];
            if (currentItems.length > 0) {
                // track updated list view since user now sees list with more items, excluding first data load
                catalogTracking.contentListViewed(
                    newItems.map((item) => ({ ...item, specialities: [] })),
                    homePartnerForumListId,
                    locale
                );
            }
            setData({
                contentDocuments: {
                    ...data?.contentDocuments,
                    ...response.data?.contentDocuments,
                    data: newItems
                }
            });
            setLoading(false);
        },
        [isDesktop, countryCode, selectedTopicCode]
    );

    const resetData = () => {
        setData(null);
        handlePartnerContentsOffsetChange(0);
    };

    useEffect(() => {
        resetData();
    }, [selectedTopicCode]);

    const { isMember } = useMembershipContext();

    const totalContents = data?.contentDocuments.totalCount || 0;

    if (!loading && totalContents === 0) {
        return null;
    }

    return (
        <Box>
            <Container maxWidth="xl" className={classes.wrappedContainer}>
                <MediathekSectionHeader
                    iconUrl="https://bk-fms-eu.storage.googleapis.com/public/static/icon-partnerforum.svg"
                    text={formatMessage({ id: 'catalog.home.partner-forum' })}
                    seeMoreUrl={CATALOG_URLS.partners + '/videos'}
                />
            </Container>
            <HorizontalList
                classes={horizontalListClasses}
                items={currentItems}
                numberOfItemsInRow={LECTURES_PER_PAGE}
                offset={offset}
                onOffsetChange={handlePartnerContentsOffsetChange}
                renderItem={(item, index) => (
                    <MediathekPartnerVideoItem
                        lecture={item}
                        isFree={showFreeBadge({
                            contentIsFree: item.isFree,
                            parentContentIsFree: item.parent?.isFree,
                            isMember
                        })}
                        index={index}
                    />
                )}
                totalCount={totalContents}
                isSkeletonVisible={loading}
                renderSkeletonItem={() => <SkeletonMediathekLectureItem contentHeight={200} />}
                listRef={listRef}
            />
        </Box>
    );
};

export const ContinueWatchingList = () => {
    const classes = useStyles();
    const horizontalListClasses = { horizontalListContainer: classes.horizontalListContainer };
    const { data: allData, loading } = useGetRecentlyWatchedContentsQuery();
    const allRecentlyWatchedItems = allData?.recentlyWatchedContents || [];
    const { locale, formatMessage } = useIntl();

    const listRef = useRef<HTMLDivElement | null>(null);
    const homeContinueWatchingListId = `${catalogTracking.useGenerateListIdFromRoute()}-continue-watching`;
    const [offset, setOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(
        allRecentlyWatchedItems.slice(0, LECTURES_PER_PAGE)
    );
    useIntersectionObserver(listRef, {}, () =>
        catalogTracking.contentListViewed(
            allRecentlyWatchedItems.map((c) => c?.content).filter(booleanFilter),
            homeContinueWatchingListId,
            locale
        )
    );

    useEffect(() => {
        if (allRecentlyWatchedItems.length) {
            setCurrentItems(allRecentlyWatchedItems.slice(offset, offset + LECTURES_PER_PAGE));
        }
    }, [offset, allRecentlyWatchedItems]);

    const totalCount = allRecentlyWatchedItems.length;

    if (!loading && allRecentlyWatchedItems.length === 0) {
        return null;
    }

    return (
        <Box>
            <Container maxWidth="xl" className={classes.wrappedContainer}>
                <MediathekSectionHeader
                    text={formatMessage({ id: 'my-fomf.latestPage.continueWatchingSectionName' })}
                    iconUrl="https://bk-fms-eu.storage.googleapis.com/public/static/forward.svg"
                />
            </Container>
            <HorizontalList
                classes={horizontalListClasses}
                items={currentItems
                    .filter(booleanFilter)
                    .map((item) => ({ ...item, id: item.contentId, content: item?.content }))}
                numberOfItemsInRow={LECTURES_PER_PAGE}
                offset={offset}
                onOffsetChange={setOffset}
                renderSkeletonItem={() => <SkeletonMediathekLectureItem contentHeight={155} />}
                renderItem={(item) => {
                    const { content } = item;
                    if (!content) {
                        return <></>;
                    }

                    return (
                        <LectureItemWithProgress
                            duration={content?.source?.duration}
                            isFree={content?.isFree || content?.parent?.isFree}
                            thumbnailUrl={content?.source?.thumbnail}
                            title={content?.title}
                            time={item.time}
                            contentId={item.contentId}
                            parentContentId={content?.parent?.contentId}
                            parentContentType={content?.parent?.contentType}
                            rating={content?.rating}
                            speakers={content?.speakers}
                            brand={content?.brand}
                            slug={content?.providers?.[0]?.companyPage?.slug}
                            id={content.id}
                            bookmarked={content?.bookmarked}
                        />
                    );
                }}
                totalCount={totalCount}
                isSkeletonVisible={loading}
                listRef={listRef}
            />
        </Box>
    );
};

type RecommendedContentsListProps = {
    selectedTopicCode: string;
};

export const RecommendedContentsList = ({ selectedTopicCode }: RecommendedContentsListProps) => {
    const classes = useStyles();
    const horizontalListClasses = { horizontalListContainer: classes.horizontalListContainer };
    const { user, isLoading: isLoadingUser } = useUserContext();
    const userId = user?.id || ('' as Types.UUID);
    const [userSpecialities, setUserSpecialities] = useState<Array<string> | null>(null);
    const [fetchUserSpecialities] = useGetUserSpecialitiesLazyQuery({
        variables: { userId }
    });
    const [fetchContents] = useGetRecommendedContentsLazyQuery();
    const { locale, formatMessage } = useIntl();
    const countryCode = getCountryCode(locale);

    const theme = useTheme();
    const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'));
    const listRef = useRef<HTMLDivElement | null>(null);
    const homeRecommendedContentListId = `${catalogTracking.useGenerateListIdFromRoute()}-recommended-contents`;
    useIntersectionObserver(listRef, {}, () =>
        catalogTracking.contentListViewed(
            currentItems.map((item) => ({ ...item, specialities: [], credits: [] })),
            homeRecommendedContentListId,
            locale
        )
    );

    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<GetRecommendedContentsQuery | null>();

    const currentItems = data?.contentDocuments?.data || [];
    const isDesktop = useIsDesktop();

    const initUserSpecialities = async () => {
        const userId = user?.id || ('' as Types.UUID);
        if (userId) {
            const specialitiesResponse = await fetchUserSpecialities({ variables: { userId } });
            const specialities = (specialitiesResponse?.data?.user?.specialities || [])
                .filter(booleanFilter)
                .map((speciality) => speciality?.code);
            setUserSpecialities(specialities);
        }
    };

    const handleRecommendedContentsOffsetChange = useCallback(
        async (newOffset: number) => {
            if (newOffset === 0 || isDesktopVersion) {
                setLoading(true);
            }
            setOffset(newOffset);
            const response = await fetchContents(
                getContentSearchQuery({
                    filters: [
                        {
                            field: 'isAvailableOnHomepage',
                            operation: Types.SearchFilterOperation.Equal,
                            value: ['true']
                        },
                        {
                            field: 'validDate',
                            operation: Types.SearchFilterOperation.GreaterThan,
                            value: [formatDateForDB(dayjs.utc().startOf('hour'))]
                        },
                        {
                            field: 'contentType',
                            value: [Types.ContentType.Lecture],
                            operation: Types.SearchFilterOperation.Equal,
                            dataType: Types.SearchFilterDataType.Enum
                        },
                        {
                            field: 'isOnDemand',
                            operation: Types.SearchFilterOperation.Equal,
                            value: ['true']
                        },
                        {
                            field: 'rating',
                            value: ['4.7'],
                            operation: Types.SearchFilterOperation.GreaterThanEqual,
                            valueWithNull: true
                        },
                        ...[
                            userSpecialities && userSpecialities.length > 0
                                ? {
                                      field: 'specialities',
                                      operation: Types.SearchFilterOperation.Contain,
                                      value: userSpecialities
                                  }
                                : undefined
                        ].filter(booleanFilter)
                    ],
                    orders: [`priorities.${selectedTopicCode}`, '-startDateTime'],
                    pageSize: LECTURES_PER_PAGE,
                    pageNumber: newOffset / LECTURES_PER_PAGE,
                    topicCode: selectedTopicCode,
                    countryCode,
                    ignoreDateFilters: true
                })
            );
            const newItems = response.data?.contentDocuments?.data ?? [];
            if (currentItems.length > 0) {
                // track updated list view since user now sees list with more items, excluding first data load
                catalogTracking.contentListViewed(
                    newItems.map((item) => ({ ...item, specialities: [], credits: [] })),
                    homeRecommendedContentListId,
                    locale
                );
            }
            setData({
                contentDocuments: {
                    ...data?.contentDocuments,
                    ...response.data?.contentDocuments,
                    data: newItems
                }
            });
            setLoading(false);
        },
        [isDesktop, countryCode, userSpecialities, selectedTopicCode]
    );

    // trigger initial data fetch on mount.
    useEffect(() => {
        handleRecommendedContentsOffsetChange(0);
    }, [selectedTopicCode]);

    const resetData = () => {
        setData(null);
        handleRecommendedContentsOffsetChange(0);
    };

    // By setting the two following "useEffect"s, first we are waiting for the user to be loaded,
    // then we are initializing the user specialities, and then we are fetching the contents
    useEffect(() => {
        if (!isLoadingUser) {
            initUserSpecialities();
        }
    }, [isLoadingUser]);

    useEffect(() => {
        if (userSpecialities != null) {
            resetData();
        }
    }, [userSpecialities]);

    const { isMember } = useMembershipContext();

    const totalContents = data?.contentDocuments.totalCount || 0;

    if (!loading && totalContents === 0) {
        return null;
    }

    return (
        <Box>
            <Container maxWidth="xl" className={classes.wrappedContainer}>
                <MediathekSectionHeader
                    text={formatMessage({ id: 'catalog.home.recommended-contents' })}
                    iconUrl="https://bk-fms-eu.storage.googleapis.com/public/static/icon-trends.svg"
                />
            </Container>
            <HorizontalList
                classes={horizontalListClasses}
                items={currentItems.map((item) => ({ ...item, specialities: [], credits: [] }))}
                numberOfItemsInRow={LECTURES_PER_PAGE}
                offset={offset}
                onOffsetChange={handleRecommendedContentsOffsetChange}
                renderItem={(item, index) => (
                    <MediathekLectureItem
                        lecture={item}
                        isFree={showFreeBadge({
                            contentIsFree: item.isFree,
                            parentContentIsFree: item.parent?.isFree,
                            isMember
                        })}
                        index={index}
                    />
                )}
                totalCount={totalContents}
                isSkeletonVisible={loading}
                renderSkeletonItem={() => <SkeletonMediathekLectureItem />}
                listRef={listRef}
            />
        </Box>
    );
};
