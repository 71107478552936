import React, { PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Icon } from 'components';
import { NextLink } from 'common';

const useStyles = makeStyles((theme) => ({
    link: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: 'fit-content',
        fontSize: '17px',
        lineHeight: '20px',
        paddingInline: '44px',
        paddingBlock: '14px',
        marginInline: theme.spacing(3),
        marginBlockStart: theme.spacing(5),
        border: `1px solid rgb(0 0 0 / 8%)`,
        borderRadius: 6,

        '&:hover': {
            textDecoration: 'underline'
        },

        [theme.breakpoints.down('md')]: {
            width: 'auto',
            justifyContent: 'center',
            marginInline: theme.spacing(0),
            marginBlockStart: theme.spacing(1)
        }
    }
}));

type SquareLinkProps = PropsWithChildren<{
    URL: string;
    className?: string;
}>;

export const SquareLink = ({ children, URL, className }: SquareLinkProps) => {
    const classes = useStyles();

    return (
        <NextLink className={classNames(classes.link, className)} href={URL}>
            {children}
            <Icon icon="arrowRight" />
        </NextLink>
    );
};
