import { LANGUAGE_TO_LOCALE, Types } from 'common';
import { LECTURES_PER_PAGE, ratingThresholdFilter } from '../../MediathekHome.utils';
import { formatDateForDB } from 'components';
import Dayjs from 'dayjs';
import getContentsDefinitionStartDateFilter from '../../../../utils/queries/getContentsDefinitionStartDateFilter';
import { ContentContentTypeFilter } from '../../../../components/Filters';

type GetHomePageTopicVideosQuery = {
    countryCode: string;
    pageNumber: number;
    language?: Language | null | undefined;
    topics: Array<string>;
    isMainTopic: boolean;
    childCodes: Array<string>;
};

export const getHomePageTopicVideosQuery = ({
    pageNumber,
    countryCode,
    topics,
    isMainTopic,
    childCodes,
    language
}: GetHomePageTopicVideosQuery) => ({
    variables: {
        searchQuery: {
            pageNumber,
            pageSize: LECTURES_PER_PAGE,
            filters: [
                {
                    field: 'isAvailableOnHomepage',
                    operation: Types.SearchFilterOperation.Equal,
                    value: ['true']
                },
                {
                    field: 'validDate',
                    operation: Types.SearchFilterOperation.GreaterThan,
                    value: [formatDateForDB(Dayjs.utc().startOf('hour'))]
                },
                {
                    field: 'publishedDomains',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [countryCode]
                },
                {
                    field: 'contentType',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [Types.ContentType.Lecture]
                },
                ...(language
                    ? [
                          {
                              field: 'language',
                              operation: Types.SearchFilterOperation.Contain,
                              value: LANGUAGE_TO_LOCALE[language]
                          }
                      ]
                    : []),
                {
                    field: 'topics.code',
                    operation: Types.SearchFilterOperation.Equal,
                    value: topics
                },
                {
                    field: 'brandType',
                    operation: Types.SearchFilterOperation.NotEqual,
                    value: [Types.ProductBrand.Summedup]
                },
                {
                    field: 'willBeOnDemand',
                    operation: Types.SearchFilterOperation.Equal,
                    value: ['true']
                },
                ...(isMainTopic && childCodes.length > 0
                    ? [
                          {
                              field: 'topics.code',
                              operation: Types.SearchFilterOperation.NotEqual,
                              value: childCodes
                          }
                      ]
                    : []),
                ...(getContentsDefinitionStartDateFilter(ContentContentTypeFilter.LECTURE) ?? []),
                ratingThresholdFilter
            ],
            orders: ['-startDateTime']
        }
    }
});
